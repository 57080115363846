import * as React from "react"
import { useState } from "react"
import { CardStyles } from "./GalleryStyles"

const Gallery = () => {
  const [checked, setChecked] = useState("img1")

  const handleChange = (event) => {
    setChecked(event.target.value)
  }

  return (
    <CardStyles>
      <div className="title" id="gallery">
        The Gallery<span style={{ color: "#f89e95" }}>.</span>
      </div>
      <div className="line"></div>
      <section id="carousel">
        <input
          type="radio"
          name="carousel"
          value="img1"
          onChange={handleChange}
          checked={checked === "img1"}
          id="s1"
        />
        <input
          type="radio"
          name="carousel"
          value="img2"
          id="s2"
          onChange={handleChange}
          checked={checked === "img2"}
        />
        <input
          type="radio"
          name="carousel"
          value="img3"
          id="s3"
          onChange={handleChange}
          checked={checked === "img3"}
        />
        <input
          type="radio"
          name="carousel"
          value="img4"
          id="s4"
          onChange={handleChange}
          checked={checked === "img4"}
        />
        <input
          type="radio"
          name="carousel"
          value="img5"
          id="s5"
          onChange={handleChange}
          checked={checked === "img5"}
        />

        <label for="s1" id="slide1">
          <img src="https://imgur.com/juMhQ9T.jpg" alt="nails" />
        </label>
        <label for="s2" id="slide2">
          <img src="https://imgur.com/LuRVjtG.jpg" alt="nails" />
        </label>
        <label for="s3" id="slide3">
          <img src="https://imgur.com/wz4VkW5.jpg" alt="nails" />
        </label>
        <label for="s4" id="slide4">
          <img src="https://imgur.com/AxZal7B.jpg" alt="nails" />
        </label>
        <label for="s5" id="slide5">
          <img src="https://imgur.com/IHTMYgB.jpg" alt="nails" />
        </label>
      </section>
    </CardStyles>
  )
}

export default Gallery
