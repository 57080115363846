import styled from "styled-components"

export const CardStyles = styled.div`
  width: 100vw;
  padding: 75px;
  background-image: url(${(props) => props.image});
  background-color: #f8b3ad;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: saturation;

  @media screen and (max-width: 480px) {
    padding: 20px 15px;
  }

  .title {
    font-size: 50px;
    text-align: center;
    font-weight: 700;
    color: #fed9ae;
    line-height: 75px;
    font-family: "PlayfairDisplay-BoldItalic";
  }

  .line {
    width: 80px;
    height: 1px;
    background-color: #ff8a80;
    margin: 30px auto;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #carousel {
    position: relative;
    width: 50%;
    height: 32vw;
    margin: 30px auto;
    font-family: "Helvetica Neue", sans-serif;
    perspective: 1400px;
    transform-style: preserve-3d;

    @media screen and (max-width: 750px) {
      width: 60%;
      height: 32vh;
      margin: 20px auto;
    }
  }

  input[type="radio"] {
    position: relative;
    top: 108%;
    left: 50%;
    width: 18px;
    height: 18px;
    margin: 0 15px 0 0;
    opacity: 0.4;
    transform: translateX(-83px);
    cursor: pointer;
    display: none;
  }

  input[type="radio"]:nth-child(5) {
    margin-right: 0px;
  }

  input[type="radio"]:checked {
    opacity: 1;
  }

  #carousel label,
  #carousel label img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    color: white;
    font-size: 70px;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 400ms ease;
  }

  /* Slider Functionality */

  /* Active Slide */
  #s1:checked ~ #slide1,
  #s2:checked ~ #slide2,
  #s3:checked ~ #slide3,
  #s4:checked ~ #slide4,
  #s5:checked ~ #slide5 {
    box-shadow: 0 13px 26px rgba(0, 0, 0, 0.3), 0 12px 6px rgba(0, 0, 0, 0.2);
    transform: translate3d(0%, 0, 0px);
    opacity: 1;
  }

  /* Next Slide */
  #s1:checked ~ #slide2,
  #s2:checked ~ #slide3,
  #s3:checked ~ #slide4,
  #s4:checked ~ #slide5,
  #s5:checked ~ #slide1 {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 2px 2px rgba(0, 0, 0, 0.2);
    transform: translate3d(20%, 0, -100px);
    opacity: 0.4;
  }

  /* Next to Next Slide */
  #s1:checked ~ #slide3,
  #s2:checked ~ #slide4,
  #s3:checked ~ #slide5,
  #s4:checked ~ #slide1,
  #s5:checked ~ #slide2 {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
    transform: translate3d(40%, 0, -250px);
    opacity: 0.4;
  }

  /* Previous to Previous Slide */
  #s1:checked ~ #slide4,
  #s2:checked ~ #slide5,
  #s3:checked ~ #slide1,
  #s4:checked ~ #slide2,
  #s5:checked ~ #slide3 {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
    transform: translate3d(-40%, 0, -250px);
    opacity: 0.4;
  }

  /* Previous Slide */
  #s1:checked ~ #slide5,
  #s2:checked ~ #slide1,
  #s3:checked ~ #slide2,
  #s4:checked ~ #slide3,
  #s5:checked ~ #slide4 {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 2px 2px rgba(0, 0, 0, 0.2);
    transform: translate3d(-20%, 0, -100px);
    opacity: 0.4;
  }
`
