import * as React from "react"
import { LocationStyles } from "./locationStyles"

const Location = () => {
  return (
    <LocationStyles className="section">
      <div className="text">
        <div className="title">
          Find us<span style={{ color: "#f89e95" }}>.</span>
        </div>
        <div className="line"></div>
        <div className="content">
          Treatments can be done in the comfort of your own home or mine
        </div>
        <div className="address">
          <div className="details">
            <h2>Jessica Le</h2>
            <p>Montague house</p>
            <p>5 Montague road</p>
            <p>Birmingham</p>
            <p>B16 9HR</p>

            <div className="opening-hours">
              <h6>- Opening Hours -</h6>
              <p>Monday 8am-19:00pm</p>
              <p>Tuesday 8am-19:00pm</p>
              <p>Wednesday 8am-19:00pm</p>
              <p>Thursday 8am-19:00pm</p>
              <p>Friday 8am-19:00pm</p>
              <p>Saturday 8am-19:00pm</p>
              <p>Sunday 9am-12:00pm</p>
            </div>
          </div>
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2430.281648799555!2d-1.9420359484463292!3d52.47403597970651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870bd077aa86da1%3A0x596a39a1e5543e40!2sMontague%20House%2C%205%20Montague%20Rd%2C%20Birmingham%20B16%209HR!5e0!3m2!1sen!2suk!4v1662490705854!5m2!1sen!2suk"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="map of little oobae corner"
            ></iframe>
          </div>
        </div>
      </div>
    </LocationStyles>
  )
}

export default Location
