import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import ImageTextModule from "../components/ImageTextModule/ImageTextModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import Location from "./../components/Location/location.js"
import Instagram from "./../components/Instagram/Instagram"
import Gallery from "../components/Carousel/Gallery"

const Index = () => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule />
        <ImageTextModule
          title="Home-based Salon"
          about="about"
          image="https://images.unsplash.com/photo-1610992015732-2449b76344bc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1331&q=80"
          content="Jessica Le is a professional mobile beauty therapist offering a variety of premium treatments.

          Relax and enjoy a treatment in the comfort of your home (or mine).
          
          Specialises in manicures, pedicures, gel polishes, acrylic extensions & nail art. 
          
          Everything can be tailored for your individual request.
          
          Flexible availability/hours with last minute bookings accepted.
          
          Book your appointment via phone/text/email;
          07719 924026 
          Jesssyle@hotmail.com"
        />
        {/* <BasicTextModule
          title="Our Journey"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis  aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
        /> */}
        <Gallery />
        <ImageTextModule
          title="Price List"
          image="https://images.unsplash.com/photo-1610992015762-45dca7fa3a85?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=664&q=80"
          menu
          content="You're never fully dressed without a good manicure! So book your appointment now."
        />
        <Location />
        <Instagram />
      </Layout>
    </>
  )
}

export default Index
