import * as React from "react"
import { BannerModuleStyles } from "./BannerModuleStyles"
import { StaticImage } from "gatsby-plugin-image"

const BannerModule = () => {
  return (
    <>
      <BannerModuleStyles>
        <div className="banner__image">
          <StaticImage
            className="image"
            src="https://imgur.com/Hja03vZ.jpg"
            alt="bubble-tea/dessert"
            placeholder="blurred"
            imgStyle={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="banner__description">
          <div className="content">
            <div className="title">
              Life is Not Perfect,
              <br /> <p className="sub-title">but your Nails can be!</p>
            </div>
            <div className="text">
              Our mission is to provide the best quality nails that are tailored
              to individual taste and preference.
            </div>
            <div className="line"></div>
          </div>
        </div>
      </BannerModuleStyles>
    </>
  )
}

export default BannerModule
